import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../../service/api.service';
import { CoreService } from '../../../../service/core.service';
import { NgbModal, ModalDismissReasons, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { NgForm } from '@angular/forms';
import { TranslateService,LangChangeEvent } from '@ngx-translate/core';
@Component({
  selector: 'app-excess-stock',
  templateUrl: './excess-stock.component.html',
  styleUrls: ['./excess-stock.component.css']
})
export class ExcessStockComponent implements OnInit {
  noItemforBarcodeMsg: boolean;
  curpage: any;
  lastpage: any;
  from: any;
  pgend: boolean;
  pgstart: boolean;
  country_dec: string;
  alias: any;
  prod_alias: any;
  product_alias: any;
  prd_alias: any;
  g_settings: any;
  generalSearch_type: any;
  defualt_gd: any;
  cmpny: any;
  all_check: boolean;
  all_data: any[];
  removeLoading: boolean;

  constructor(private apiService: ApiService, private coreService: CoreService, private modalService: NgbModal,private translate: TranslateService) { }
  modalRef: NgbModalRef; selecTed: any = {}; trans_stocks = [];
  closeResult: string;
  printError = []; errObjArr = {
    unitqtyErr: null, gdErr: null,
    prodErr: null, dtErr: null, unitErr: null, dmgdErr: null, dmqtyErr: null
  };
  
  excs_types = [
    { id: 1, name: 'Diesel Mix' },
    { id: 2, name: 'LB' },
   
  ];
  fuel_type={
    0: 'Nill',
    1: 'Diesel Mix',
    2: 'LB',
    
  };
  excess_stocks: any;
  up: any;
  stkprd_all: any;
  unit: any;
  gsl_from: any;
  godown_all: any;
  units_all: any;
  sel_unit: any;
  unit_base_qty: any;
  unit_name: any;
  unit_qty: number;
  slno: any;
  pageLoadingImg: boolean;
  damage_date: Date;
  noItem: boolean;
  stkprdsel: any;
  qty: string;
  stk_unit_qty: string;
  damage_notes: string;
  cur_lang: string;
  ngOnInit() {
    this.getGeneralSetting();
    this.cur_lang = sessionStorage.getItem("baseLang");
      this.aliasChecked()
      this.apiService.cur_lang.subscribe(value =>{
        this.cur_lang = value;
        this.aliasChecked()
      })
    this.getExcessStockList('');
    this.up = this.coreService.getUserPrivilage();
    this.damage_date = new Date();
    this.noItem = false;
    this.noItemforBarcodeMsg = false;
    this.country_dec=this.coreService.setDecimalLength();
    this.defualt_gd=this.coreService.getUserData('usr_default_godown_id');
    this.cmpny = this.coreService.getUserData('cmpny');
    
  }

  aliasChecked(){
    if(this.cur_lang !=undefined && this.cur_lang !=null && this.cur_lang !='English' ){
      this.prod_alias = 'alias_true';
      this.alias='alias_checked';
    }else{
      this.prod_alias =null;
      this.alias=null;
    }
  }

  //form search product
  searchStkPrd(search: any, eventChar) {
    let searchval = new FormData();
    if(this.alias){
      searchval.append('alias_search', '1');
    }
    searchval.append("keyword", search);
    this.apiService.getStockbyItem(searchval).subscribe((res) => {
      this.stkprd_all = res['data'];
      $('#addpurchase').hide();

    });
  }

  //form search product by barcode / ean

  searchStkPrdBarcode(search: any) {
    let searchval = new FormData();
    searchval.append("barcode", search);
    this.apiService.getStockbyBarcode(searchval).subscribe((res) => {
      // this.stkprd_all = res['data'];
     if(res.data){
      this.noItemforBarcodeMsg = false;
      this.unit = res.data.produnit_unit_id;
      this.gsl_from = null;
      this.selecTed = res['data'];
      this.getGodown('');
      this.getUnits();
      this.stkprdsel = {
        prd_name: res.data.prd_name

      };
      this.selecTed.prd_code = search;
      this.unit_qty = 0;

    } else {
      this.selecTed = [];
      this.noItemforBarcodeMsg = true;
      this.unit = null;
      this.stkprdsel = {
        prd_name: null

      };
    }
    //  console.log(res.data.length);
      
    //  console.log(res['data']);
      
      $('#addpurchase').hide();

    });
  }


  selectStockPrdSel(stkprdsel) {
    if (stkprdsel) {
      this.unit = null;
      this.gsl_from = null;
      this.selecTed = stkprdsel;
      this.getGodown('');
      this.getUnits();
      this.unit_qty = 0;
      $("#excsqty").focus();
    } else {
      this.selecTed = [];

    }
  }


  getGodown(search: string) {
    let searchval = new FormData();
    searchval.append("gd_name", search);
    searchval.append("prd_id", this.selecTed.prd_id);
    this.apiService.getGodownItemwise(searchval).subscribe((res) => {
      this.godown_all = res['data'];
      this.godown_all.push({ "gd_id": 0, 'gd_name': 'Shop' });
      this.gsl_from=this.defualt_gd;
    });

  }

  getUnits() {
    let searchval = new FormData();
    searchval.append("prd_id", this.selecTed.prd_id);
    this.apiService.getUnitsItemwise(searchval).subscribe((res) => {
      this.units_all = res['data'];
      this.unit=this.selecTed.prd_base_unit_id;
      this.selectUnits(this.unit);
    });

  }

  selectGd(godown_id) {
    // if (godown_id) {

      let searchval = new FormData();
      searchval.append("prd_id", this.selecTed.prd_id);
      searchval.append("gd_id", godown_id);
      this.unit_qty = 0;

      this.apiService.getGodownStock(searchval).subscribe((res) => {

        if (godown_id != 0) {
          this.selecTed.bs_stock_quantity_shop = res['data'][0]['gs_qty'];
          // console.log(res['data'][0]['gs_qty']);
        } else {
          this.selecTed.bs_stock_quantity_shop = res['data'][0]['bs_stock_quantity_shop'];
        }
        // this.unit = null;
        this.selectUnits(this.unit);
      // console.log("Godown : "+godown_id);
      // console.log(this.selecTed.bs_stock_quantity_shop);
      });
    // }
    
  }

  selectUnits(unitid: string) {
    if (unitid) {
      this.sel_unit = this.units_all.find(x => x.unit_id === unitid).unit_id;
      this.unit_base_qty = this.units_all.find(x => x.unit_id === unitid).unit_base_qty;
      this.unit_name = this.units_all.find(x => x.unit_id === unitid).unit_name;
      this.unit_qty = this.selecTed.bs_stock_quantity_shop / this.units_all.find(x => x.unit_id === unitid).unit_base_qty;
    }
  }

  addToList(form) {
    console.log("test",form.value);
    
    this.printError = [];
    if (form.value.qty == "" || form.value.qty == undefined) {
      this.errObjArr.unitqtyErr = "t";
      this.printError.push({ 'fieldErr': 'Required' });
    }
    // else if (form.value.qty > this.unit_qty) {
    //   this.errObjArr.unitqtyErr = "t";
    //   this.printError.push({ 'fieldErr': 'Required' });
    // }
    else {
      this.errObjArr.unitqtyErr = "f";
    }

    if (form.value.gsl_from == undefined) {
      this.errObjArr.gdErr = "t";
      this.printError.push({ 'fieldErr': 'Required' });
    }
    else {
      this.errObjArr.gdErr = "f";
    }


    if (form.value.unit == undefined) {
      this.errObjArr.unitErr = "t";
      this.printError.push({ 'fieldErr': 'Required' });
    }
    else {
      this.errObjArr.unitErr = "f";
    }

    if (form.value.damage_date == undefined) {
      this.errObjArr.dtErr = "t";
      this.printError.push({ 'fieldErr': 'Required' });
    }
    else {
      if (form.value.damage_date > new Date()) {
        this.errObjArr.dtErr = "t";
        this.printError.push({ 'fieldErr': 'Required' });
      } else {
        this.errObjArr.dtErr = "f";
      }
    }

    if (form.value.stkprdsel == undefined) {
      this.errObjArr.prodErr = "t";
      this.printError.push({ 'fieldErr': 'Required' });
    }
    else {
      this.errObjArr.prodErr = "f";
    }


    if (this.printError.length <= 0) {
      this.slno = this.trans_stocks.length + 1;
      //check duplicate
      const { length } = this.trans_stocks;
      const id = length + 1;
      const prd = this.trans_stocks.some(el => el.prd_id === form.value.prd_id);
      const gsl_from = this.trans_stocks.some(el => el.gsl_from === form.value.gsl_from);

      if (!prd)
        this.trans_stocks.push({
          "sl_no": this.slno, "prd_id": form.value.prd_id, "itemname": form.value.itemname, "prd_alias": form.value.stkprdsel.prd_alias, "qty": form.value.qty * this.unit_base_qty, "unit_id": this.sel_unit, "unit_name": this.unit_name, "added_qty": form.value.qty, "gsl_from": form.value.gsl_from,
          "damage_date": form.value.damage_date, "ext_fuel_type": form.value.ext_fuel_type,
          "from_product": form.value.from_prd,"damage_notes": form.value.damage_notes
        });
      this.unit = null;
      this.gsl_from = null;
      // form.reset();
      // this.damage_date = new Date();
      this.selecTed = {};
      this.stkprdsel = this.stkprdsel[0];
      // this.stkprdsel = '';
      this.qty = '';
      this.stk_unit_qty = '';
      this.unit_qty = 0;
      this.damage_notes = '';
    }

  }

  deleteProd(data) {
    var remopstk = confirm("Removing Selected Product from the table");
    if (remopstk) {
      var type = data.sl_no,
        i;
      for (i = this.trans_stocks.length - 1; i >= 0; --i) {
        if (this.trans_stocks[i].sl_no == type) {
          this.trans_stocks.splice(i, 1);
        }
      }

    }
  }


  openModal(content) {

    this.modalRef = this.modalService.open(content, { size: 'lg', backdrop: 'static' });
    this.modalRef.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
    this.damage_date = new Date();


  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  getExcessStockList(prd_id) {
    let searchval = new FormData();
    searchval.append("keyword", prd_id);
    if(this.prod_alias){
      searchval.append('alias_search', '1');
    }
    this.apiService.getExcessStockList(searchval,1).subscribe((res) => {
      this.excess_stocks = res.data.data;
        this.curpage = res.data['current_page'];
      this.lastpage = res.data['last_page'];
      this.from = res.data['from'];
      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);


    });

  }

  pgEnd(curr, end) {
    if (curr == end)
      this.pgend = true;
    else
      this.pgend = false;

  }

  pgStart(curr) {
    if (curr == 1)
      this.pgstart = true;
    else
      this.pgstart = false;
  }

  pageNext(keyword,pageNo){
    let searchval = new FormData();
    if(keyword)
    searchval.append("keyword", keyword);
    this.apiService.getExcessStockList(searchval,pageNo).subscribe((res) => {
      this.excess_stocks = res.data.data;
        this.curpage = res.data['current_page'];
      this.lastpage = res.data['last_page'];
      this.from = res.data['from'];
      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);

    });
  }


  submitMissingStock(form: NgForm) {

    if (this.trans_stocks.length > 0) {



      if (confirm('Do you wish to continue?')) {
        this.noItem = false;

        form.value.prdlist = this.trans_stocks;
        form.value.damage_date = (form.value.damage_date) ?
          this.apiService.formatDate(form.value.damage_date) : form.value.damage_date;
        this.pageLoadingImg = true;
        this.apiService.addExcessStock(form.value).subscribe((res) => {
          this.coreService.showMessage('Added Successfully');
          this.trans_stocks = [];
          form.reset();
          this.selecTed = [];
          this.modalRef.close();
          this.unit = null;
          this.pageLoadingImg = false;


          this.getExcessStockList('');
        });
      }
    } else {
      this.noItem = true;
    }
  }
  removeAllExcess(data){
    if (confirm("Do you wish to void this entry?")) {
     
      this.all_check=false;
      this.all_data=[];
      data.forEach((element,i) => {
        
        var tmpRow = {
          stock: element.excs_id,
         
        }
        this.all_data.push(tmpRow);
       
    });
      this.removeLoading=true;
      this.apiService.voidAllExcessStock({products:this.all_data}).subscribe((res) => {
        this.all_check=true;
        this.removeLoading=false;
        if (res.message)
          this.coreService.showMessage('Voided Successfully');
        this.getExcessStockList('');

      });

    }
  }


  removeExcessStock(id, i=0) {
    
    if(confirm("Do you wish to void this entry?")){
    let searchval = new FormData();
    this.excess_stocks[i].disable = true;  
    searchval.append("stock", id);
    this.apiService.voidExcessStock(searchval).subscribe((res) => {
      if (res.message)
        this.coreService.showMessage('Voided Successfully');
        this.getExcessStockList('');

    });

  }

  }

  getGeneralSetting() {

    this.apiService.getGeneralSetting().subscribe((res) => {
  
      // console.log(res['data']);
      this.g_settings = res['data'];
      this.generalSearch_type = this.g_settings.g_s_search_type;
      console.log(this.generalSearch_type);
      
    });
  
  }

}
