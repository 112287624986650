import { Component, OnInit,Input } from '@angular/core';
import { CoreService } from 'src/app/service/core.service';
import { TranslateService,LangChangeEvent } from '@ngx-translate/core';

@Component({
  selector: 'app-po-general-print',
  templateUrl: './po-general-print.component.html',
  styleUrls: ['./po-general-print.component.css']
})
export class PoGeneralPrintComponent implements OnInit {
  @Input()branch_name;
  @Input()savedTemplate;
  @Input()logoImgUrl;
  @Input()branch_address;
  @Input()branch_phone;
  @Input()branch_mob;
  @Input()branch_reg_no;
  @Input()branch_tin;
  @Input()tempImgUrl;
  @Input()footImgUrl;
  @Input()previewData;
  @Input()is_prew_description;
  @Input()sales_settings;
  @Input()itemcode_show;
  @Input()showrate;
  country_dec: any;
  cmpny: any;
  constructor( private coreService: CoreService,private translate: TranslateService) { }

  ngOnInit() {
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      // this.langChange();
    });
    this.country_dec=this.coreService.setDecimalLength();
    this.cmpny = this.coreService.getUserData('cmpny');
  }
  closePreview() {
  }
}
