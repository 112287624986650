import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/service/api.service';
import { CoreService } from 'src/app/service/core.service';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import * as XLSX from 'xlsx';


@Component({
  selector: 'app-outstanding-recievable',
  templateUrl: './outstanding-recievable.component.html',
  styleUrls: ['./outstanding-recievable.component.css']
})
export class OutstandingRecievableComponent implements OnInit {
  branch_display_name: any;
  branch_display_code: any;
  country_dec: any;
  pageLoadingImg: boolean;
  pgend: boolean;
  pgstart: boolean;
  pageFrom: any;
  curpage: any;
  lastpage: any;
  exportLoader: boolean;
  recievable_list: any;
  recievablelist: any;
  fileName = 'oustanding_recievable.xlsx';

  constructor(private apiService: ApiService, private coreService: CoreService,private translate: TranslateService,) { }

  ngOnInit() {
    this.branch_display_name = this.coreService.getUserData('branch_disp_name');
    this.branch_display_code = this.coreService.getUserData('branch_code');
    this.country_dec=this.coreService.setDecimalLength();
    this.apiService.getOutstandingRecievable('',1).subscribe((res) => {
      this.pageLoadingImg = false;
      this.recievable_list = res.data.data;
      console.log(' this.recievable_list ', this.recievable_list );
      
      this.curpage = res['data']['current_page'];
      console.log('curpage',this.curpage);
      
      this.lastpage = res['data']['last_page'];
      this.pageFrom = res['data']['from'];
      // this.totalData = res['total'];
      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
  
    });
  }
  pageNext( pageno: any){
    this.pageLoadingImg = true;
    this.apiService.getOutstandingRecievable('', pageno).subscribe((res) => {
      this.pageLoadingImg = false;
      this.recievable_list = res.data.data;
      console.log(' this.recievable_list ', this.recievable_list );
      
      this.curpage = res['data']['current_page'];
      console.log('curpage',this.curpage);
      this.lastpage = res['data']['last_page'];
      this.pageFrom = res['data']['from'];
      // this.totalData = res['total'];
      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
  
    });
   

  }

  pgEnd(curr, end) {
    if (curr == end)
      this.pgend = true;
    else
      this.pgend = false;

  }

  pgStart(curr) {
    if (curr == 1)
      this.pgstart = true;
    else
      this.pgstart = false;
  }
   exportexcel(): void {
       
        /* table id is passed over here */
        const element = document.getElementById('export-group');
        const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
    
        /* generate workbook and add the worksheet */
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    
        /* save to file */
        XLSX.writeFile(wb, this.fileName);
        this.exportLoader = false;
    
      }
    exportEXCL(){
      this.exportLoader = true;
      let searchval = new FormData();
      searchval.append("export",'export');
      this.apiService.getOutstandingRecievable(searchval,1).subscribe((res)=> {       
        this.recievablelist = res.data.data; 
        setTimeout(() => {
          this.exportexcel();
        }, 3000);
        
      });
    }

}
